.Header_container {
    display: flex;
    position: fixed;
    width: 98.5vw;
    height: 50px;
    color: white;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: var(--main-color);

    padding-left: 15px;
    padding-right: 15px;
}

.Header_container .donateSection {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.Header_container .searchSection {
    display: flex;
    justify-content: center;
    width: 100%;
}
.Header_container .searchBar {
    flex: 15;
    border: solid 1px var(--background-color);
    border-radius: 10px 0px 0px 10px;
    font-size: larger;
    background-color: var(--background-color);
    color: var(--text-color);
    padding: 5px;
    width: 100%;
    margin-right: 0px;
}
.Header_container .searchButton {
    flex: 1;
    border: solid 1px var(--background-color);
    border-radius: 0px 10px 10px 0px;
    background-color: var(--main-color);
}
.Header_container .searchButton:hover {
    background-color: var(--main-color-hover);
}

.Header_container .typeSection {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.Header_container .dropdown {
    border: none;
    background-color: var(--background-color);
    color: white;
    padding: 5px;
    border-radius: 10px;
    font-size: larger;
}