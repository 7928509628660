:root {
	--main-color: #CC6600;
	--main-color-25: #CC660044;
	--main-color-50: #CC660088;
	--main-color-75: #cc6600cc;
	--main-color-90: #cc6600e6;
	--main-color-hover: #864300;
	--donate-button-color: #0070ba;
	--donate-button-color-hover: #004777;
	--text-color: white;
	--background-color: #221200;

	background-color: var(--background-color);
	overflow: hidden;
}

.App_container {
	height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.App_container.mobile {
	display: flex;
	width: 100%;
	height: 100vh;
	justify-content: center;
	align-items: center;
}
.App_container.mobile img {
	margin-top: 20px;
	width: 100%;
	max-width: 512px;
}
.App_container.mobile button {
	border: none;
	background: none;
}

.home {
	display: flex;
	justify-content: center;
	align-items: center;
    margin-top: 50px;
	height: calc(100% - 50px);
}
.home p {
	margin-left: -10px;
	font-size: 10em;
	color: white;
	font-weight: bold;
	opacity: 0;
	transition: 1s;
}
.home.animation p {
	opacity: 1;
	margin-left: 30px;
}
.home img {
	width: 15%;
	height: auto;
	background: radial-gradient(#fff 0%, transparent 30%);
	margin-right: -10px;
	opacity: 0;
	transition: 1s;
}
.home.animation img {
	opacity: 1;
	margin-right: 30px;
}

.notFound {
    margin-top: 50px;
    height: 93vh;
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
	justify-content: center;
	align-items: center;
	font-size: xx-large;
	color: var(--text-color);
}