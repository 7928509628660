.DonateButton_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--donate-button-color);
    color: var(--text-color);
    font-size: larger;
    border-radius: 10px;
    border: none;
    padding: 5px;
}

.DonateButton_container:hover {
    background-color: var(--donate-button-color-hover);
}