.Modal_wrapper {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 1;
    display: flex;
    visibility: visible;
    justify-content: flex-start;
    align-items: center;
    background-color: #000c;
    transition: 0.3s;
}
.Modal_wrapper.hidden {
    opacity: 0;
    visibility: hidden;
}

/*.Modal_container {
    width: calc(100% - 200px);
    height: calc(100% - 200px);
    background-color: var(--main-color-90);
    border: solid 2px #fff4;
    border-radius: 15px;
}*/

.Modal_container {
    position: relative;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background-color: var(--main-color-90);
    border: solid 2px #fff4;
    border-left: none;
    border-radius: 0 15px 15px 0;
    transition: 0.3s;
}

.Modal_container.hidden {
    left: -50%;
}