.ItemCard_container {
    flex: 1;
    display: flex;
    margin: 10px;
    border: 1px solid transparent;
    min-height: 450px;
}
@media only screen and (max-width: 2560px) {
    .ItemCard_container {
        flex-basis: calc(16.67% - 30px);
    }
}
@media only screen and (max-width: 1920px) {
    .ItemCard_container {
        flex-basis: calc(20% - 30px);
    }
}
@media only screen and (max-width: 1360px) {
    .ItemCard_container {
        flex-basis: calc(33% - 30px);
    }
}
@media only screen and (max-width: 1366px) {
    .ItemCard_container {
        flex-basis: calc(33% - 30px);
    }
}
.ItemCard_container .card:hover {
    box-shadow: 0 0 25px var(--main-color);
}

.ItemCard_container .card {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: var(--main-color);
    border: solid 1px var(--main-color);
    border-radius: 15px;
    cursor: pointer;
    transition: .3s;
}

.ItemCard_container .card img {
    flex: 10;
    border-radius: 15px 15px 0px 0px;
}

.ItemCard_container .card div {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}