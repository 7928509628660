.ItemModal_container {
    margin: 10px;
    display: flex;
    flex-direction: column;
    color: white;
    height: calc(100% - 20px);
}

.ItemModal_container .header {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.ItemModal_container .header h2 {
    flex: 1;
    font-size: xx-large;
    font-weight: bold;
}
.ItemModal_container .header button {
    border: none;
    background: none;
    cursor: pointer;
}

.ItemModal_container .content {
    flex: 10;
    display: flex;
}

.ItemModal_container .content .left {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5px;
}
.ItemModal_container .content .left .img {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.ItemModal_container .content .left .img img {
    border-radius: 15px;
    flex: 1;
}

.ItemModal_container .content .left .infoSection {
    flex: 1;
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: 20px;
}
.ItemModal_container .content .left .infoSection div {
    height: 75px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    font-size: larger;
    border-bottom: dashed 1px #fff8;
}

.ItemModal_container .content .textSection {
    flex: 2;
    flex-direction: column;
    display: flex;
}
.ItemModal_container .content .textSection .seriesSelection {
    height: 10%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.ItemModal_container .content .textSection .seriesSelection select {
    border: solid 2px #0004;
    background-color: #0004;
    color: white;
    font-size: X-large;
    padding: 10px;
    border-radius: 15px;
}

.ItemModal_container .content .textSection .optionSection {
    height: 80%;
    margin: 5px;
    flex-flow: row wrap;
    border: solid 2px #0004;
    border-radius: 15px 0 0 15px;
    overflow-y: scroll;
    scrollbar-color: #0004 #0004;
}
.ItemModal_container .content .textSection .optionSection.series {
    height: 70%;
}
.ItemModal_container .content .textSection .optionSection::-webkit-scrollbar, .ItemModal_container .content .textSection .optionSection::-webkit-scrollbar-thumb {
    background-color: #0004;
}
.ItemModal_container .content .textSection .optionSection::-webkit-scrollbar-thumb:hover {
    background-color: #0006;
}

.ItemModal_container .content .textSection .downloadButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.ItemModal_container .content .textSection .downloadButton button {
    color: white;
    font-size: 2.5em;
    border: solid 2px #0004;
    background-color: #0004;
    border-radius: 15px;
    padding: 15px;
}
.ItemModal_container .content .textSection .downloadButton button:hover {
    background-color: #0006;
    cursor: pointer;
}


.MovieOption_container {
    display: inline-flex;
    flex-grow: 1;
    flex-direction: column;
    width: calc(50% - 10px);
    min-height: 50px;
    max-height: 80px;
    padding: 10px;
    margin: 5px;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: larger;
    background-color: #0006;
    border: solid 1px #fff4;
    border-radius: 15px;
}

.MovieOption_container:hover {
    background-color: #000c;
}